import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';

import { debounce } from "../debounce";

function InputDialog(props) {
  const { input, setInput, recalculate } = props;
  const [localInput, setLocalInput] = React.useState("");
  const [firstVisit, setFirstVisit] = React.useState(true);
  const handleChange = (event) => {
    setFirstVisit(false);
    setLocalInput(event.target.value);
  };
  useEffect(() => {
    if (localInput !== "") {
      verify(localInput);
    }
  }, [localInput]);

  const verify = useCallback(
    debounce(i => {
      // console.log(i);
      setInput(i)
    }, 200),
    []
  );
  return (
    <Box
      component="form"
      // sx={{
      //   '& .MuiTextField-root': { m: 1, width: 'auto' },
      // }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="keysmash-input"
        label="Keysmash Here"
        multiline
        fullWidth
        autoFocus
        rows={4}
        value={localInput}
        onChange={handleChange}
        />
        <Typography variant="caption">
          {firstVisit ? '' : "If your score hasn't calculated, try clicking the button below."}
        </Typography>
        {!firstVisit ? (
          <span>
            <div style={{ margin: "1em" }} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={recalculate} variant="contained">Recalculate</Button>
            </Box>
          </span>
        ) : ''}
        
        
        
    </Box>
  )
}

InputDialog.propTypes = {
  input: PropTypes.string.isRequired,
  setInput: PropTypes.func.isRequired,
  recalculate: PropTypes.func.isRequired,
}

export default InputDialog