import { Alert, Box, Link, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { appName, appVersion, appUrl } from '../consts'
import InputDialog from './InputDialog';
import Score from './Score';

import { Share } from 'react-twitter-widgets';

import astraBlush  from '../assets/images/astraBlush.png'

function App() {

  const [score, setScore] = React.useState(0);
  const [input, setInput] = React.useState('');
  const [recalculate, setRecalculate] = React.useState(false);
  const [query, setQuery] = React.useState('idle');

  return (
    <Container fixed>
      <Typography variant="h2">{appName}</Typography>
      <Typography variant="caption">rev. {appVersion} | Bottom Detection Algorithm Subject to Change</Typography>

      <div style={{ margin: "2em" }} />

      <Alert severity="warning">
        <Typography variant="body1">
          Please consider answering a short survey to help improve the accuracy of the algorithm. <Link href="https://go.astrabun.com/survey" target="_blank">Click here to take the survey.</Link>
        </Typography>
      </Alert>

      <div style={{ margin: "2em" }} />

      <Score query={query} setQuery={setQuery} score={score} setScore={setScore} input={input} recalculate={recalculate} />
      
      <div style={{ margin: "1em" }} />
      
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <InputDialog input={input} setInput={setInput} recalculate={() => setRecalculate(!recalculate)} />
      </Box>
      
      <div style={{ margin: "2em" }} />
      
      {/* Tweet Button */}
      {query === 'success' ? (
        <span>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Share
              // size="large"
              url={appUrl}
              className="twitter-share-button"
              options={{
                dnt: true,
                hashtags: "bottomdetector",
                related: "astra_bun",
                text: `I am ${score}% a bottom, according to ${appName} v${appVersion}!`,
                // via: "astra_bun",
              }}
            />
            <a
              href={`https://twitter.com/intent/tweet?hashtags=bottomdetector&original_referer=https%3A%2F%2Fbottomdetector.astrabun.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&related=astra_bun&text=I%20am%20${score}%25%20a%20bottom%2C%20according%20to%20Bottom%20Detector%20v${appVersion}!&url=https%3A%2F%2Fbottomdetector.astrabun.com`}
              target="_blank"
              rel="noreferrer"
            >
              Tweet your result!
            </a>
          </Box>
        </span>
      ) : ""}

      <div style={{ margin: "2em" }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Link href="https://astrabun.com" target={'_blank'} rel="noreferrer">
          <img src={astraBlush} style={{ height: '10em' }} alt="AstraBun" />
        </Link>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="caption">
          ❤, AstraBun
        </Typography>
      </Box>

      <div style={{ margin: "6em" }} />
    </Container>
  )
}

export default App