import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Fade, Typography } from '@mui/material';

import words from '../words.json'

const entropy = (str) => {
  const len = str.length
 
  // Build a frequency map from the string.
  const frequencies = Array.from(str)
    .reduce((freq, c) => (freq[c] = (freq[c] || 0) + 1) && freq, {})
 
  // Sum the frequency of each character.
  return Object.values(frequencies)
    .reduce((sum, f) => sum - f/len * Math.log2(f/len), 0)
}

const keywords = [
  'harder',
  'daddy',
  'uwu',
  'buldge',
  'bulge',
  'ffffuck',
  'owo',
  'nuzzles',
  'musky',
  'musk',
  'gimme',
  'pwease',
  'naughty',
  'hewwo'
];

let dictionary = words.filter(word => !keywords.includes(word.toLowerCase()))

function Score(props) {
  const { query, setQuery, score, setScore, input, recalculate } = props;
  const [msg, setMsg] = React.useState('');
  // const [query, setQuery] = React.useState('idle');
  const timerRef = React.useRef();
  
  const calcScore = () => {
    let calculated = 0;

    let base = 0;
    let keywordsPresentCount = 0;

    let patternWeight = 7;
    let punctuationWeight = 2;
    let capitalLowerRatioWeight = 2;
    let lengthWeight = 3;
    let entropyWeight = 1;
    let numWeight = 2;

    let wordPresenceWeight = 20;
    if(input.length < 32) {
      wordPresenceWeight += 10;
    } else if(input.length < 64) {
      wordPresenceWeight += 10;
    }

    // check for known words in the input
    let wordsPresent = 0;
    dictionary.forEach(word => {
      if (input.includes(word.toLowerCase()) || input.toUpperCase().includes(word)) {
        wordsPresent++;
      }
    })
    let wordPresenceAdd = wordsPresent * wordPresenceWeight;

    let inputArray = input.split(' ');
    let astraPresent = false;
    let bottomPresent = false;
    inputArray.forEach(word => {
      if (word.toLowerCase() === 'astra') {
        astraPresent = true;
      }
      if (word.toLowerCase() === 'bottom') {
        bottomPresent = true;
      }
    })

    keywords.forEach(keyword => {
      if(input.toLowerCase().includes(keyword)) {
        base += 25;
        keywordsPresentCount += 1;
      }
    })

    if (astraPresent && bottomPresent) {
      setMsg('Astra is NOT a bottom!!!!!!!');
      calculated = 100;
    } else {
      setMsg('');

      // count number of numbers
      let numCount = 0;
      inputArray.forEach(word => {
        if (word.match(/[0-9]/g)) {
          numCount++;
        }
      })
      let numAdd = parseInt(numCount * numWeight);

      // letter checks
      let aCount,sCount,dCount,fCount,jCount,kCount,lCount,wCount,uCount,iCount,oCount;
      aCount = sCount = dCount = fCount = jCount = kCount = lCount = wCount = uCount = iCount = oCount = 0;
      [...input].forEach(char => {
        if (char.toLowerCase() === 'a') {
          aCount++;
        }
        if (char.toLowerCase() === 's') {
          sCount++;
        }
        if (char.toLowerCase() === 'd') {
          dCount++;
        }
        if (char.toLowerCase() === 'f') {
          fCount++;
        }
        if (char.toLowerCase() === 'j') {
          jCount++;
        }
        if (char.toLowerCase() === 'k') {
          kCount++;
        }
        if (char.toLowerCase() === 'l') {
          lCount++;
        }
        if (char.toLowerCase() === 'w') {
          wCount++;
        }
        if (char.toLowerCase() === 'u') {
          uCount++;
        }
        if (char.toLowerCase() === 'i') {
          iCount++;
        }
        if (char.toLowerCase() === 'o') {
          oCount++;
        }
      })
      let baseLetterCount = aCount + sCount + dCount + fCount + jCount + kCount + lCount + wCount + uCount + iCount + oCount;
      let baseLetterWeight = (baseLetterCount / input.length)*2;
      let baseLetterAddition = parseInt(baseLetterWeight * baseLetterWeight);

      // pattern checks

      // check if every character is the same and input length is over 9
      let sameCharCount = 0;
      let sameCharAddition = 0;
      let sameChar = input[0];
      [...input].forEach(char => {
        if (char === sameChar) {
          sameCharCount++;
        }
      }
      )
      if (sameCharCount === input.length && input.length > 9) {
        sameCharAddition = 50;
      }


      // count how many times the string 'asd' appears in the input
      let asdfCount = 0;
      let asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('asd', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just as
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('as', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just asj
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('asj', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just sadf
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('sadf', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just kj
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('kj', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just gkh
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('gkh', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just hnn
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('hnn', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just fads
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('fads', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just nnnnnn, as in hnnnnn
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('nnnnnn', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }
      // search again for just hhhh, as in hhhhhnnnnnnnnnnnn
      asdfIndex = 0;
      while (asdfIndex !== -1) {
        asdfIndex = input.toLowerCase().indexOf('hhhh', asdfIndex);
        if (asdfIndex !== -1) {
          asdfCount++;
          asdfIndex++;
        }
      }

      // count how many times the string 'jkl;' appears in the input
      let jklCount = 0;
      let jklIndex = 0;
      while (jklIndex !== -1) {
        jklIndex = input.toLowerCase().indexOf('jkl', jklIndex);
        if (jklIndex !== -1) {
          jklCount++;
          jklIndex++;
        }
      }

      // count how many times the string 'qwe' appears in the input
      let qwerCount = 0;
      let qwerIndex = 0;
      while (qwerIndex !== -1) {
        qwerIndex = input.toLowerCase().indexOf('qwe', qwerIndex);
        if (qwerIndex !== -1) {
          qwerCount++;
          qwerIndex++;
        }
      }

      // count how many times the string 'uio' appears in the input
      let uiopCount = 0;
      let uiopIndex = 0;
      while (uiopIndex !== -1) {
        uiopIndex = input.toLowerCase().indexOf('uio', uiopIndex);
        if (uiopIndex !== -1) {
          uiopCount++;
          uiopIndex++;
        }
      }

      let patternCount = asdfCount + jklCount + qwerCount + uiopCount;
      let patternCountAdd = patternCount * patternWeight;

      // length check
      let inputLength = input.length;
      let inputScoreAdd = 0.005*Math.pow(inputLength, 2);
      inputScoreAdd = parseInt(inputScoreAdd);

      // punctuation/symbol checks
      let punctuationCount = 0;
      // let specialChars be a list of special characters
      let specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=', '{', '}', '[', ']', '|', ':', ';', '"', '\'', '<', '>', '?', '/', '.', ','];
      
      [...input].forEach(char => {
        if (specialChars.includes(char)) {
          punctuationCount++;
        }
      })
      let puncScoreAdd = parseInt(0.5*punctuationCount);

      // lower to capital ratio -- if > 0.5, score += 10
      let lowerToCapitalRatio = 0;
      // find the ratio of lowercase letters to uppercase letters
      let lowerCount = 0;
      let upperCount = 0;
      [...input].forEach(char => {
        if (char.toLowerCase() === char) {
          lowerCount++;
        }
        if (char.toUpperCase() === char) {
          upperCount++;
        }
      });
      lowerToCapitalRatio = upperCount > 0 ? lowerCount / upperCount : 0;
      capitalLowerRatioWeight = lowerToCapitalRatio > 0 ? capitalLowerRatioWeight/lowerToCapitalRatio : 1;
      let lowerToCapitalRatioAdd = 0;
      if (lowerToCapitalRatio > 0) {
        lowerToCapitalRatioAdd = 10*capitalLowerRatioWeight*lowerToCapitalRatio;
      }

      // calculate the entropy
      let calcEntropy = entropy(input);
      let entropyExtraFactor = 1;
      if (input.length < 32) {
        if (calcEntropy >= 2.7) {
          entropyExtraFactor = 5;
        } else if (calcEntropy >= 2.65) {
          entropyExtraFactor = 4;
        } else if (calcEntropy >= 2.6) {
          entropyExtraFactor = 1.5;
        }
      } else {
        if (calcEntropy >= 3.3) {
          entropyExtraFactor = 5;
        } else if (calcEntropy >= 3.0) {
          entropyExtraFactor = 4;
        } else if (calcEntropy >= 2.9) {
          entropyExtraFactor = 1.5;
        }
      }
      entropyExtraFactor = entropyExtraFactor / (1+(keywordsPresentCount*100))
      let entropyAdd = parseInt(((Math.pow(calcEntropy * entropyWeight, 2)))*entropyExtraFactor);
      if(calcEntropy < 2.8) {
        entropyAdd = entropyAdd * -1;
      }
      if (keywordsPresentCount >= 3) {
        base = 75;
        entropyAdd = Math.abs(entropyAdd)
        wordPresenceAdd = Math.abs(entropyAdd)
      }

      // calculate the score
      // calculated = patternCountAdd + inputScoreAdd + puncScoreAdd + lowerToCapitalRatioAdd;
      calculated = base + numAdd + entropyAdd + baseLetterAddition + sameCharAddition + patternCountAdd + inputScoreAdd + puncScoreAdd + lowerToCapitalRatioAdd - wordPresenceAdd;
      // console.log(keywordsPresentCount)
      // console.log(entropyAdd)
      // console.log(calculated)
      // console.log("---")
      

      let rawScoreCalculationDetails = { // eslint-disable-line
        // simple
        aaaa_INPUT_length: input.length,

        // weights
        patternWeight: patternWeight,
        punctuationWeight: punctuationWeight,
        capitalLowerRatioWeight: capitalLowerRatioWeight,
        lengthWeight: lengthWeight,
        baseLetterWeight: baseLetterWeight,
        entropyWeight: entropyWeight,

        // entropy
        calcEntropy: calcEntropy,
        entropyAdd: entropyAdd,

        // numbers
        numWeight: numWeight,
        numCount: numCount,
        numAdd: numAdd,

        // base letter counts
        baseLetterCount: baseLetterCount,
        aCount: aCount,
        sCount: sCount,
        dCount: dCount,
        fCount: fCount,
        jCount: jCount,
        kCount: kCount,
        lCount: lCount,
        wCount: wCount,
        uCount: uCount,
        iCount: iCount,
        oCount: oCount,
        baseLetterAddition: baseLetterAddition,

        // patterns
        sameCharCount: sameCharCount,
        sameCharAddition: sameCharAddition,
        asdfCount: asdfCount,
        jklCount: jklCount,
        qwerCount: qwerCount,
        uiopCount: uiopCount,
        totalPatternCount: patternCount,
        patternCountAdd: patternCountAdd,
        
        // length
        inputLength: inputLength,
        inputScoreAdd: inputScoreAdd,

        // punctuation
        punctuationCount: punctuationCount,
        puncScoreAdd: puncScoreAdd,

        // lower to capital ratio
        upperCount: upperCount,
        lowerCount: lowerCount,
        lowerToCapitalRatio: lowerToCapitalRatio,
        lowerToCapitalRatioAdd: lowerToCapitalRatioAdd,

        // words
        wordsPresent: wordsPresent,
        wordPresenceAdd: wordPresenceAdd,
        wordPresenceWeight: wordPresenceWeight,

        calculated: calculated,
      }
      // console.log(rawScoreCalculationDetails); // DEBUG
    }
    if (calculated > 100) {
      calculated = 100;
    }
    if (calculated < 0) {
      calculated = 0;
    }
    setScore(calculated);
  }

  const handleClickQuery = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (query !== 'idle' || input.length === 0) {
      setQuery('idle');
      return;
    }

    setQuery('progress');
    calcScore(); // calculate the score
    timerRef.current = window.setTimeout(() => {
      setQuery('success');
    }, 2000);
  };

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    [],
  );

  React.useEffect(() => {
    handleClickQuery();
    // console.log(query)
  }, [input, recalculate]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center' }}>
      <Box>
        {query === 'success' ? (
          <Box sx={{ width: "75%", margin: 'auto' }}>
            <LinearProgress variant="determinate" value={score} />
            <br />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6">
                {score}% Bottom
              </Typography>
              {msg.length > 0 ? (
                <Typography variant="h6">
                  {msg}
                </Typography>
              ) : ""}
            </Box>
          </Box>
        ) : (
          <Fade
            in={query === 'progress'}
            style={{
              transitionDelay: query === 'progress' ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>Calculating...</Box>
          </Fade>
        )}
      </Box>
    </Box>
  )
}

Score.propTypes = {
  score: PropTypes.number.isRequired,
  setScore: PropTypes.func.isRequired,
  input: PropTypes.string.isRequired,
  recalculate: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
}

export default Score