import { createTheme } from "@mui/material";

export const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
};

export const sitetheme = createTheme(themeOptions);